<template>
  <v-container >
    <v-card width="100%" height="auto" class="mx-auto pa-0">
      <v-card-text>
        <v-layout row class="justify-center">
          <v-card elevation-5 width="135px" class=" elevation-5 pa-0 ml-0 mr-2 mt-1" color="teal darken-4">
            <v-card-text class="pa-1">
              <div class="stitle">최대 출력</div>
              <v-row>
                <v-slider dense
                  v-model="sliderMaxVent1"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMaxVent1}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH1</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderMaxVent2"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMaxVent2}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH2</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderMaxVent3"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMaxVent3}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH3</div>
                  </template>
                </v-slider>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card elevation-5 width="135px" class=" elevation-5 pa-0 ml-0 mr-2 mt-1" color="teal darken-4">
            <v-card-text class="pa-1">
              <div class="stitle">최소 출력</div>
              <v-row>
                <v-slider dense
                  v-model="sliderMinVent1"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMinVent1}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH1</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderMinVent2"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMinVent2}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH2</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderMinVent3"
                  class="align-center"
                  max="99"
                  min="0"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderMinVent3}}%</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH3</div>
                  </template>
                </v-slider>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card elevation-5 width="135px" class=" elevation-5 pa-0 pa-0 ml-0 mr-2 mt-1" color="cyan darken-4">
            <v-card-text class="pa-1">
              <div class="stitle">설정 온도</div>
              <v-row>
                <v-slider dense
                  v-model="sliderSetTemp1"
                  class="align-center"
                  max="30"
                  min="10"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderSetTemp1}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH1</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderSetTemp2"
                  class="align-center"
                  max="30"
                  min="10"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderSetTemp2}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH2</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderSetTemp3"
                  class="align-center"
                  max="30"
                  min="10"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderSetTemp3}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH3</div>
                  </template>
                </v-slider>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card elevation-5 width="135px" class=" elevation-5 pa-0 pa-0 ml-0 mr-2 mt-1" color="cyan darken-4">
            <v-card-text class="pa-1">
              <div class="stitle">온도 편차</div>
              <v-row>
                <v-slider dense
                  v-model="sliderDeviTemp1"
                  class="align-center"
                  max="10"
                  min="1"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderDeviTemp1}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH1</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderDeviTemp2"
                  class="align-center"
                  max="10"
                  min="1"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderDeviTemp2}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH2</div>
                  </template>
                </v-slider>
                <v-slider
                  v-model="sliderDeviTemp3"
                  class="align-center"
                  max="10"
                  min="1"
                  step="1"
                  ticks="always"
                  tick-size="3"
                  thumb-label
                  track-color="teal lighten-5"
                  color="teal lighten-3"
                  vertical
                  hide-details
                >
                  <template v-slot:append>
                    <div class="top_value">{{sliderDeviTemp3}}℃</div>
                  </template>
                  <template v-slot:prepend>
                    <div class="channel">CH3</div>
                  </template>
                </v-slider>
              </v-row>
            </v-card-text>
          </v-card>

        </v-layout>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

export default {
  name: 'SettingPannel',

  data: () => ({
    sliderMaxVent1: 99,
    sliderMaxVent2: 80,
    sliderMaxVent3: 66,
    sliderMinVent1: 20,
    sliderMinVent2: 20,
    sliderMinVent3: 0,
    // min: -50,
    // max: 90,
    // slider: 80,

  }),
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    this.$store.commit("resMessage","원격 제어 진행중으로 적용되지 않습니다.")
  },

  methods: {
    refreshList() {
      this.currentDT = dateUtil.format(new Date(), "yyyy-MM-dd HH:mm" );
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loading = true;
      // console.log("리스트");
      Apis.listControllerSetting({
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.sensor_mac = Common.getMacMask(element.sensor_mac);
        });
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loading = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listSensorsOnOff 호출 오류",err);
        alert("listControllerSetting 호출 오류" + err);
        this.$rchannel.push("/");
        this.loadingRoom = false;
      }) 
    }, 
    offItemConfirm () {
      this.$store.commit("resMessage","");
      this.loading = true;
      let currentDT = dateUtil.format(new Date(), "yyyy-MM-dd");
      Apis.updateSensorFree({
          // "id" : this.sensorID,
          "sensor_mac" : this.sensor_mac,
          "deldate" :	currentDT || "",
          "history" : this.historyOff.trim() || "",
          },(res) => {  
            if (res.result) {
              this.loading = false;
              this.historyOff = "";
              this.$store.commit("resMessage"," 사용중지 " + res.message);
              this.items[this.editedIndex].deldate = currentDT;
              this.dialogOff = false;
            } else {
              this.loading = false;
              alert("offItemConfirm API 호출 오류" + res.message)
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSaveSensor = false;
              console.log("offItemConfirm API 호출 오류",err)
              alert(err);
          }
        )
    },

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.cell_top{
  // text-align:right;
  // font-weight: bold;
  padding-left: 2px;
  font-size: 12px;
  color: white;
  background-color: #1B5E20;
}
.cell_bottom{
  // text-align:right !important;
  // font-weight: bold;
  font-size: 12px;
  color: white !important;
  background-color: #1B5E20;
  }
.channel {
  font-size: 13px;
  color: #FFEA00;
  padding-left: 7px;
  text-align: center;
  margin-bottom: 10px;
}
.top_value {
  font-size: 13px;
  font-weight: bold;
  color:white;
  margin-top: 15px;
  margin-bottom: -5px;
  padding-left: 5px;
  padding-right: 8px;
  text-align: center;
}
.stitle {
  color: #FFEA00;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

</style>
